import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { colors, device } from '../../styles/config'

import CheckSvg from '../../images/check.svg'
import UncheckSvg from '../../images/uncheck.svg'

import useCalendarLimit from '../../hooks/useCalendarLimit'
import Calendar from './RoomCalendar'

const Container = styled.div`
  background: white;
  padding: 3.5rem;

  @media ${ device.lg } {
    padding: 2rem;
  }

  @media ${ device.md } {
    padding: 1rem;
  }

  > div {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const List = styled.ul`
  list-style:none;
  margin: 0;
`

const ListItem = styled.li`
  font-size: 1rem;
  line-height: 2.1875;
  letter-spacing: 0.6px;
  display: flex;

  div {
    flex: 1;
  }
`

const ListIcon = styled.span`
  width: 1rem;
  height: 2.1875rem;
  background-image: url(${ props => props.enabled ? CheckSvg : UncheckSvg });
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center;
  margin-right: 0.59375rem;
`

const DownloadItem = styled.a`
  font-size: 1rem;
  line-height: 2.1875;
  letter-spacing: 0.6px;
  display: block;
  color: ${ colors.secondary };
`

const RoomDetails = ({ general, technical, infrastructure, downloads, internalId }) => {
  const calendarLimit = useCalendarLimit()

  return (
    <>
      <Container>
        {general &&
        <div>
          <h3>Allgemein</h3>
          <List>
            {general.map(({ name, enabled }, index) => (
              <ListItem key={index}>
                <ListIcon enabled={enabled}/>
                <div>{name}</div>
              </ListItem>
            ))}
          </List>
        </div>
        }
        {technical &&
        <div>
          <h3>Technik</h3>
          <List>
            {technical.map(({ name, enabled }, index) => (
              <ListItem key={index}>
                <ListIcon enabled={enabled}/>
                <div>{name}</div>
              </ListItem>
            ))}
          </List>
        </div>
        }
        {infrastructure &&
        <div>
          <h3>Infrastruktur</h3>
          <List>
            {infrastructure.map(({ name, enabled }, index) => (
              <ListItem key={index}>
                <ListIcon enabled={enabled}/>
                <div>{name}</div>
              </ListItem>
            ))}
          </List>
        </div>
        }
        {downloads &&
        <div>
          <h3>Downloads</h3>
          {downloads.map((file, index) => (
            <DownloadItem key={index} href={file.url} target="blank">{file.title}</DownloadItem>
          ))}
        </div>
        }

      </Container>
      <Calendar internalId={internalId} calendarLimit={calendarLimit}/>
    </>
  )
}
RoomDetails.propTypes = {
  // children: PropTypes.node.isRequired,
  // light: PropTypes.bool,
  // to: PropTypes.string,
}

export default RoomDetails
