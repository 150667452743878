import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '../../styles/config'
import { StyledCalendar } from '../Calendar'

import { brueckendeckUrl, sonnendeckUrl, inselgartenUrl, theaterdeckUrl } from '../../../config/website'
import { compareDates } from '../../utils/time'

const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  margin-top: 1.75rem;

  .react-calendar {
    width: 100% !important;
    margin-top: 0;
    margin-left: 0;
  }

  .react-calendar__navigation,
  .react-calendar__month-view {
    padding: 0 3rem;

    @media ${ device.lg } {
      padding: 0 1.5rem;
    }

    @media ${ device.md } {
      padding: 0 0.5rem;
    }

    @media ${ device.sm } {
      padding: 0;
    }
  }

  .react-calendar__month-view {
    pointer-events: none;
  }

  .react-calendar__navigation button {
    min-width: 30px;

    @media ${ device.sm } {
      min-width: 44px;
    }
  }

  .react-calendar__navigation__next-button {
    text-align: right;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    text-align: left;

    @media ${ device.sm } {
      text-align: center;
    }
  }

  .react-calendar__tile {
    height: 40px;
    width:  40px;
  }
`

class RoomCalendar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dates: []
    }
  }

  componentDidMount () {
    fetch(getRequest(this.props.internalId))
      .then(
        response => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
              response.status)
            return
          }
          // Examine the text in the response
          response.json().then(data => {
            this.setState({
              dates: data.items.map(item => {
                return {
                  start: item.start.date,
                  end: item.end.date
                }
              })
            })
          })
        }
      )
      .catch(err => {
        console.log('Fetch Error:', err)
      })
  }

  checkDisabled = ({ activeStartDate, date, view }) => {
    if (!this.state.dates || this.state.dates.length === 0) {
      return false
    }

    if (new Date(date).getDay() === 0) {
      return true
    }

    return this.state.dates.filter(entry => compareDates(entry.start, entry.end, date)).length > 0
  }

  render () {
    const { calendarLimit } = this.props

    return (
      <CalendarContainer>
        <StyledCalendar
          locale="de-DE"
          next2Label={null}
          prev2Label={null}
          showNeighboringMonth={true}
          showFixedNumberOfWeeks={true}
          minDate={new Date()}
          maxDate={new Date(calendarLimit)}
          minDetail={'month'}
          selectRange={false}
          tileDisabled={this.checkDisabled}
        />
      </CalendarContainer>
    )
  }
}

function getRequest (room) {
  switch (room) {
  case 'brueckendeck':
    return brueckendeckUrl
  case 'wiese':
    return inselgartenUrl
  case 'sonnendeck':
    return sonnendeckUrl
  case 'theaterdeck':
    return theaterdeckUrl
  }
}

RoomCalendar.propTypes = {
  // siteTitle: PropTypes.string,
  // currentPathname: PropTypes.string,
}

RoomCalendar.defaultProps = {
  // siteTitle: ``,
}

export default RoomCalendar
