import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { colors } from '../styles/config'
import GatsbyImage from 'gatsby-image'
import { Col, Row } from 'react-styled-flexboxgrid'

const Container = styled.div`
  
`

// const FirstLandscapeImage = styled(GatsbyImage)`
//   width: 100%;
//   margin-bottom: 1.875rem;
// `

const Image = styled(GatsbyImage)`
  margin-bottom: 1.875rem;
`

const OtherImages = styled.div`
  width: 100%;
  columns: 2;
  column-gap: 1.875rem;
`

const ImageGrid = ({ images }) => {
  const portraitMode = isPortraitMode(images)
  let firstImage
  const leftGroup = images.filter((image, index) => index % 2 === 0)
  const rightGroup = images.filter((image, index) => index % 2 !== 0)

  if (!portraitMode) {
    firstImage = leftGroup.shift()
  }

  return (
    <>
      <Container>
        {!portraitMode &&
          <Row>
            <Col xs={12}>
              <Image fluid={firstImage.fluid}/>
            </Col>
          </Row>
        }
        <Row>
          <Col xs={12} sm={portraitMode ? 6 : 8}>
            {leftGroup.map((image, index) => <Image key={index} fluid={image.fluid}/>)}
          </Col>
          <Col xs={12} sm={portraitMode ? 6 : 4}>
            {rightGroup.map((image, index) => <Image key={index} fluid={image.fluid}/>)}
          </Col>
        </Row>
      </Container>

    </>
  )
}

function isPortraitMode (images) {
  return images.filter(image => {
    return image.fluid.aspectRatio < 1
  }).length > 1
}

ImageGrid.propTypes = {
  // children: PropTypes.node.isRequired,
  // light: PropTypes.bool,
  // to: PropTypes.string,
}

export default ImageGrid
