import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'
import GatsbyImage from 'gatsby-image'
import scrollToComponent from 'react-scroll-to-component'

import Background from '../components/Background'
import Social from '../components/Social'
import { colors } from '../styles/config'
import ImageGrid from '../components/ImageGrid'
import RoomDetails from '../components/room/RoomDetails'
import HouseHeader from '../components/HouseHeader'
import slugify from '../utils/slugify'

const Container = styled.div`
  ${ props => props.theme.wrapper }
`

const RoomPage = ({
  location,
  data: {
    datoCmsRoom: {
      name,
      internalId,
      description,
      images,
      general,
      technical,
      infrastructure,
      downloads
    }
  }
}) => {
  const detailsRef = useRef()
  const onAvailabiltyClick = () => {
    scrollToComponent(detailsRef.current, {
      align: 'bottom',
      duration: 1250,
      offset: 200,
      ease: 'inOutCube'
    })
  }

  return (
    <>
      <HouseHeader
        header={name}
        description={description}
        activeRoom={internalId}
        onAvailabiltyClick={onAvailabiltyClick}
        location={location}
      />
      <Background openTop>
        <Container>
          <Row>
            <Col xs={12} sm={8}>
              <ImageGrid images={images}/>
            </Col>
            <Col xs={12} sm={4} ref={detailsRef}>
              <RoomDetails
                general={general}
                technical={technical}
                infrastructure={infrastructure}
                downloads={downloads}
                internalId={internalId}
              />
            </Col>
          </Row>
          <Social/>
        </Container>
      </Background>
    </>
  )
}

// ad back in
// downloadedFile {
//   publicURL
// }

export const query = graphql`
  query($originalId: String!) {
    datoCmsRoom(originalId: {eq: $originalId}) {
      name
      internalId
      description
      general {
        name
        enabled
      }
      technical {
        name
        enabled
      }
      infrastructure {
        name
        enabled
      }
      downloads {
        title
        url
      }
      images {
        fluid(maxWidth: 800, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`

export default RoomPage
